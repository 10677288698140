import { defineStore } from "pinia";
import auth from "@/services/auth.js";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: null,
  }),
  actions: {
    login(username, password) {
      return auth.login(username, password);
    },
    async getInfo() {
      const r = await auth.getInfo();
      const u = r.data;
      this.user = u;
      return u;
    },
    logout() {
      this.user = null;
      return auth.logout();
    },
    async checkIfAuthenticated() {
      let result = false;
      try {
        const user = await this.getInfo();
        if (user) result = true;
      } catch (e) {
        console.error(e);
      }
      return result;
    },
  },
  getters: {
    getUser: (state) => state.user,
    isAuthenticated: (state) => !!state.getUser,
    checkRole: (state) => (role) => {
      if (!state.getUser) return false;
      return state.getUser.roles.includes(role);
    },
  },
});
