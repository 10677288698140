import axios from "@/services/axiosInstance";

function login(username, password) {
  return axios.post("../auth/login", { username, password });
}

function getInfo() {
  return axios.get("../auth/info");
}

function logout() {
  return axios.post("../auth/logout");
}

export default {
  login,
  getInfo,
  logout,
};
