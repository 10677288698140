import * as Sentry from "@sentry/vue";

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
const apiUrl = import.meta.env.VITE_API_URL;

const apiUrlRegex = new RegExp(`^${apiUrl}`);

function init(app, router) {
  return Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [apiUrlRegex],
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", apiUrlRegex, /^\//],

    // Capture Replay for 10% of all sessions, plus for 100% of sessions with an error
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default {
  init,
};
