import axios from "axios";
import { useAuthStore } from "@/stores/auth.js";

const instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) await useAuthStore().logout();
    return Promise.reject(error);
  },
);

export default instance;
