import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth.js";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@views/HomeView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/categories",
      name: "categories",
      component: () => import("@views/CategoriesView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/items",
      name: "items",
      component: () => import("@views/ItemsView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/items/:itemId",
      name: "item",
      component: () => import("@views/ItemView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/rentals",
      name: "rentals",
      component: () => import("@views/RentalsView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/rentals/:rentalId",
      name: "rental",
      component: () => import("@views/RentalView.vue"),
      meta: { requiresAuth: true },
    },
    {
      path: "/auth/login",
      name: "auth-login",
      component: () => import("@views/auth/LoginView.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await useAuthStore().checkIfAuthenticated()) {
      next();
    } else {
      next({ name: "auth-login" });
    }
  } else {
    next();
  }
});

export default router;
